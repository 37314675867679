import React from 'react';
import GameMetaData from './GameMetaData';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import { stripHtmlAndTruncate } from '../utils';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const CardImageContainer = styled('a')(() => ({
  display: 'block',
  height: '225px',
  width: '100%',
  overflow: 'hidden',
}));

const CardImage = styled(CardMedia)(() => ({
  transition: '0.3s',
  height: '100%',
  objectFit: 'cover',

  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const CardContentWrapper = styled(CardContent)(() => ({
  paddingBottom: '16px !important',
  position: 'relative',
  zIndex: '1',
}));

const CardTitleContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const StyledTitle = styled(Typography)(() => ({
  color: 'var(--primary-color) !important',
}));

const CardTitle = styled(Typography)(() => ({
  marginBottom: 0,
}));

const ViewsContainer = styled('span')(({ theme }) => ({
  fontSize: '0.75rem',
  opacity: '0.5',
}));

const ButtonsWrapper = styled(CardActions)(() => ({
  justifyContent: 'flex-end',
}));

const CardButton = styled(Button)(() => ({
  color: 'white !important',
}));

const VisitSite = styled('span')(() => ({}));

const NewTabIcon = styled(LaunchIcon)(() => ({
  marginLeft: '0.25rem !important',
  fontSize: '12px !important',
}));

export default function GameCard({
  image,
  title,
  description,
  views,
  link,
  slug,
  platform = [],
  category = [],
  company = [],
}) {
  const processedDescription = stripHtmlAndTruncate(description, 150);

  return (
    <Card>
      {/* {image ? (
        <CardImageContainer href={link} target='_blank'>
          <CardImage
            component='img'
            alt={'Image of ' + title}
            height='140'
            image={image}
          />
        </CardImageContainer>
      ) : null} */}

      <CardContentWrapper>
        <CardTitleContainer>
          <StyledTitle
            gutterBottom
            variant='h5'
            component={Link} // Make the title a clickable link
            to={`/game/${slug}`} // Link to the tool detail page
            style={{ textDecoration: 'none', color: 'inherit' }} // Optional: remove underline and use inherited color
          >
            {title}
          </StyledTitle>
          {/* <ViewsContainer>
            <i className='eye icon' />
            {views}
          </ViewsContainer> */}
        </CardTitleContainer>
        <Typography variant='body2' color='text.secondary'>
          {processedDescription}
        </Typography>
      </CardContentWrapper>

      <GameMetaData category={category} platform={platform} company={company} />

      <ButtonsWrapper>
        <CardButton
          component={Link}
          size='small'
          to={`/game/${slug}`} // Link to the game detail page
          variant='contained'
        >
          Read More
        </CardButton>
      </ButtonsWrapper>
    </Card>
  );
}
