import { styled } from '@mui/material/styles';

const StyledGridContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4),
  gridTemplateColumns: 'repeat(3, 1fr)',
  marginBottom: theme.spacing(4),

  '@media only screen and (max-width: 1200px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  '@media only screen and (max-width: 768px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  '@media only screen and (max-width: 600px)': {
    gridTemplateColumns: '1fr',
  },
}));

function GridContainer({ containerId, children, className }) {
  return (
    <>
      <StyledGridContainer id={containerId} className={className}>
        {children}
      </StyledGridContainer>
    </>
  );
}

export default GridContainer;
