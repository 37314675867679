import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Skeleton } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import PostMetaData from './PostMetaData';

const PageWrapper = styled('main')(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '48px',
  padding: '16px 0',
}));

const DetailsWrapper = styled('div')(() => ({
  backgroundColor: 'white',
  // maxWidth: '1200px',
  width: '100%',
  borderRadius: '4px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
}));

const ImageContainer = styled('div')(() => ({}));

const Image = styled('img')(() => ({
  width: '100%',
  maxHeight: '500px',
  objectFit: 'cover',
  borderRadius: '4px 4px 0 0',
}));

const DetailsContainer = styled('div')(() => ({
  padding: 'var(--spacing-5) 48px',
  '@media only screen and (max-width: 991px)': {
    padding: 'var(--spacing-5)',
  },
}));

const CardTitleContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const Title = styled('h1')(() => ({}));

const Description = styled('div')(() => ({
  marginTop: '32px',
}));

const ButtonWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}));

const CardButton = styled(Button)(() => ({
  color: 'var(--primary-color) !important',
  marginTop: '32px',
}));

const NewTabIcon = styled(LaunchIcon)(() => ({
  marginLeft: '0.25rem !important',
  fontSize: '12px !important',
  color: 'var(--primary-color) !important',
}));

const SkeletonLoader = () => (
  <DetailsWrapper>
    <Skeleton
      variant='rectangular'
      width='100%'
      height={500}
      style={{ marginBottom: '24px' }}
    />
    <DetailsContainer>
      <CardTitleContainer>
        <Skeleton variant='text' width='60%' height={40} />
      </CardTitleContainer>
      {Array.from(new Array(5)).map((_, idx) => (
        <Skeleton
          key={idx}
          variant='text'
          width='100%'
          height={20}
          style={{ marginBottom: '16px' }}
        />
      ))}
      <Skeleton
        variant='rectangular'
        width='30%'
        height={40}
        style={{ marginTop: '32px' }}
      />
    </DetailsContainer>
  </DetailsWrapper>
);

export default function PostDetails() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPost = async () => {
      try {
        const response = await fetch(
          `https://backend-apis.utilitips.com/wp-json/wp/v2/posts?slug=${slug}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const post = data[0];
        setPost(post);
        document.title = `UtiliTips - ${post.title.rendered}`; // Update the document title with the post's title

        // Fetch the featured image if it exists
        if (post.featured_media) {
          const mediaResponse = await fetch(
            `https://backend-apis.utilitips.com/wp-json/wp/v2/media/${post.featured_media}`
          );
          if (!mediaResponse.ok) {
            throw new Error(
              `HTTP error fetching media! status: ${mediaResponse.status}`
            );
          }
          const mediaData = await mediaResponse.json();
          setFeaturedImage(mediaData.source_url); // Set the featured image URL
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (loading) {
    return (
      <PageWrapper>
        <SkeletonLoader />
      </PageWrapper>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Function to add <br /> after each </p>
  const addBreaksToHtml = (html) => {
    return html.replace(/<\/p>/g, '</p><br />');
  };

  return (
    <PageWrapper>
      {post ? (
        <DetailsWrapper>
          {featuredImage ? (
            <ImageContainer>
              <Image src={featuredImage} alt={post.title.rendered} />
            </ImageContainer>
          ) : null}

          <DetailsContainer>
            <CardTitleContainer>
              <Title>{post.title.rendered}</Title>
            </CardTitleContainer>

            {/* <PostMetaData
              categories={post.categories}
              author={post.author}
              tags={post.tags}
            /> */}

            <Description
              dangerouslySetInnerHTML={{
                __html: addBreaksToHtml(post.content.rendered),
              }}
            />

            {/* <ButtonWrapper>
              <CardButton
                href={post.link}
                target='_blank'
                rel='noopener noreferrer'
              >
                Visit Site
                <NewTabIcon />
              </CardButton>
            </ButtonWrapper> */}
          </DetailsContainer>
        </DetailsWrapper>
      ) : (
        <div>Post not found</div>
      )}
    </PageWrapper>
  );
}
