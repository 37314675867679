import React, { useState, useEffect } from 'react';

import ToolsListing from '../Tools/ToolsListing';
import ToolsFilter from '../Tools/ToolsFilters';
import ResponsiveAppBar from '../Header/Header';
import PageHeadingContainer from '../Containers/PageHeadingContainer';

const Home = () => {
  const [filters, setFilters] = useState({
    searchTerm: '',
    type: '',
    category: '',
    company: '',
    platform: '',
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const initialFilters = {
      searchTerm: urlParams.get('search') || '',
      type: urlParams.get('type') || '',
      category: urlParams.get('category') || '',
      company: urlParams.get('company') || '',
      platform: urlParams.get('platform') || '',
    };
    setFilters(initialFilters);
  }, []);

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div>
      <PageHeadingContainer pageHeading='Useful Tools' />

      <ToolsFilter onApplyFilters={handleApplyFilters} />

      <ToolsListing filters={filters} />
    </div>
  );
};

export default Home;
