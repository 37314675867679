import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

const pages = [
  { name: 'Useful Tools', path: '/' },
  { name: 'Games', path: '/games' },
  { name: 'Blog', path: '/blog' },
  { name: 'Privacy Policy', path: '/privacy-policy' },
];

const StyledAppBar = styled(AppBar)(() => ({
  marginTop: '32px',
  backgroundColor: 'white',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
}));

const StyledMenuItem = styled(Button)(({ active }) => ({
  opacity: 1,
  color: active ? '#2A76D2 !important' : 'grey',
  justifyContent: 'flex-start',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: 'unset !important',
  },
}));

const DownloadButton = styled('span')(({ theme }) => ({
  backgroundColor: 'var(--primary-color)',
  color: 'white',
  padding: theme.spacing(1),
  borderRadius: '4px',
  cursor: 'pointer',
}));

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isPWAInstallable, setIsPWAInstallable] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setIsPWAInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the PWA installation');
        } else {
          console.log('User dismissed the PWA installation');
        }
        setDeferredPrompt(null);
        setIsPWAInstallable(false);
      });
    }
  };

  const LogoImage = styled('img')(() => ({
    maxWidth: '200px',
    '@media only screen and (max-width: 450px)': {
      maxWidth: '100px',
    },
  }));

  return (
    <StyledAppBar position='sticky'>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Typography
            variant='h6'
            noWrap
            component={Link}
            to='/'
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <LogoImage
              src='/assets/images/utilitips-logo.webp'
              alt='UtiliTips logo'
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='menu'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='black'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <StyledMenuItem
                    component={Link}
                    to={page.path}
                    active={location.pathname === page.path}
                    sx={{ color: 'inherit' }}
                  >
                    {page.name}
                  </StyledMenuItem>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant='h5'
            noWrap
            component={Link}
            to='/'
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <LogoImage
              src='/assets/images/utilitips-logo.webp'
              alt='UtiliTips logo'
            />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <StyledMenuItem
                key={page.name}
                component={Link}
                to={page.path}
                active={location.pathname === page.path}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, display: 'block' }}
              >
                {page.name}
              </StyledMenuItem>
            ))}
          </Box>
          {isPWAInstallable && (
            <DownloadButton onClick={handleInstallClick}>
              Download App
            </DownloadButton>
          )}
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
}

export default ResponsiveAppBar;
