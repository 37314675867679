import React from 'react';
import { Link } from 'react-router-dom';
import ToolMetaData from './ToolMetaData';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import { stripHtmlAndTruncate } from '../utils';

const StyledCard = styled(Card)(() => ({
  position: 'relative',
  display: 'grid',
}));

const CardImageContainer = styled('a')(() => ({
  display: 'block',
  height: '225px',
  width: '100%',
  overflow: 'hidden',
}));

const CardImage = styled(CardMedia)(() => ({
  transition: '0.3s',
  height: '100%',
  objectFit: 'cover',

  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const CardTitleContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const StyledTitle = styled(Typography)(() => ({
  color: 'var(--primary-color) !important',
}));

const ViewsContainer = styled('span')(({ theme }) => ({
  fontSize: '0.75rem',
  opacity: '0.5',
}));

const ButtonsWrapper = styled(CardActions)(() => ({
  justifyContent: 'flex-end',
  padding: '16px',
}));

const CardButton = styled(Button)(() => ({
  color: 'white !important',
}));

const VisitSite = styled('span')(() => ({
  color: 'var(--primary-color) !important',
  lineHeight: '0',
}));

const NewTabIcon = styled(LaunchIcon)(() => ({
  marginLeft: '0.25rem !important',
  fontSize: '12px !important',
  color: 'var(--primary-color) !important',
}));

export default function ToolCard({
  image,
  title,
  excerpt,
  description,
  views,
  link,
  slug,
  type,
  categories = [],
  company = [],
  platform = [],
  loading,
  externalButtonLabel,
}) {
  const processedDescription = stripHtmlAndTruncate(description, 150);

  return (
    <StyledCard>
      {image ? (
        <CardImageContainer href={`/tool/${slug}`}>
          <CardImage
            component='img'
            alt={'Image of ' + title}
            height='140'
            image={image}
          />
        </CardImageContainer>
      ) : null}

      <CardContent>
        <CardTitleContainer>
          <StyledTitle
            gutterBottom
            variant='h5'
            component={Link} // Make the title a clickable link
            to={`/tool/${slug}`} // Link to the tool detail page
            style={{ textDecoration: 'none', color: 'inherit' }} // Optional: remove underline and use inherited color
          >
            {title}
          </StyledTitle>
          <ViewsContainer>
            {views ? <i className='eye icon' /> : null}

            {views}
          </ViewsContainer>
        </CardTitleContainer>
        <Typography variant='body2' color='text.secondary'>
          {processedDescription}
        </Typography>
      </CardContent>

      <ToolMetaData
        type={type}
        categories={categories}
        company={company}
        platform={platform}
        loading={loading}
      />

      <ButtonsWrapper>
        <Button
          component={Link}
          size='small'
          to={link}
          target='_blank'
          variant='text'
          sx={{ color: 'var(--primary-color) !important' }}
        >
          <VisitSite>{externalButtonLabel}</VisitSite>
          <NewTabIcon />
        </Button>

        <CardButton
          component={Link}
          size='small'
          to={`/tool/${slug}`} // Link to the tool detail page
          variant='contained'
        >
          Read More
        </CardButton>
      </ButtonsWrapper>
    </StyledCard>
  );
}
