import React from 'react';
import CardMetaData from '../Shared/CardMetaData';

const GameMetaData = ({
  platform = [],
  category = [],
  company = [],
  className,
}) => {
  const combinedMetaData = [
    ...category.map((item) => ({ ...item, type: 'category' })),
    ...platform.map((item) => ({ ...item, type: 'platform' })),
    ...company.map((item) => ({ ...item, type: 'company' })),
  ];

  return <CardMetaData className={className} metaData={combinedMetaData} />;
};

export default GameMetaData;
