import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import GridContainer from '../Containers/GridContainer';
import GameCard from './GameCard';
import SkeletonLoader from '../Cards/SkeletonCard';
import { styled } from '@mui/material/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Typography from '@mui/material/Typography';

const ScrollToTopButton = styled('button')(() => ({
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  backgroundColor: 'var(--brand-main-color)',
  color: 'white',
  border: 'none',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
  transition: '0.3s ease',
  '&:hover': {
    backgroundColor: 'var(--primary-color)',
  },
}));

const UpArrowIcon = styled(ArrowUpwardIcon)(() => ({
  marginRight: '0 !important',
}));

export default function GamesListing({ filters }) {
  const [cardsData, setCardsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const bottomSentinel = useRef();
  const location = useLocation(); // Hook to track URL changes

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'UtiliTips - Games';
  }, []);

  const fetchItems = async (page, filters) => {
    setLoading(true);
    try {
      const query = new URLSearchParams({
        page,
        search: filters.searchTerm,
        platform: filters.platform,
      }).toString();

      const response = await fetch(
        `https://backend-apis.utilitips.com/wp-json/ut/v1/games?${query}`
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setCardsData((prevCards) =>
        page === 1 ? data : [...prevCards, ...data]
      );
      setHasMore(data.length > 0);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
    setCardsData([]);
    fetchItems(1, filters);
  }, [filters]);

  useEffect(() => {
    if (page > 1) {
      fetchItems(page, filters);
    }
  }, [page, filters]);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && !loading && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    [loading, hasMore]
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '100px',
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver(handleObserver, options);
    if (bottomSentinel.current) {
      observer.current.observe(bottomSentinel.current);
    }

    return () => {
      if (observer.current && bottomSentinel.current) {
        observer.current.unobserve(bottomSentinel.current);
      }
    };
  }, [handleObserver]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Sync URL with filters and page
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('page', page);
    if (filters.searchTerm) {
      urlParams.set('search', filters.searchTerm);
    } else {
      urlParams.delete('search');
    }
    if (filters.platform) {
      urlParams.set('platform', filters.platform);
    } else {
      urlParams.delete('platform');
    }
    window.history.pushState({}, '', `?${urlParams.toString()}`);
  }, [page, filters, location.search]);

  return (
    <>
      <GridContainer>
        {loading && !cardsData.length ? (
          Array.from(new Array(6)).map((_, index) => (
            <SkeletonLoader key={index} />
          ))
        ) : cardsData.length === 0 && !loading ? (
          <Typography variant='h6'>No games found!</Typography>
        ) : (
          cardsData.map((card, index) => (
            <GameCard
              key={index}
              image={card.featured_image}
              title={card.title}
              description={card.content}
              views={card.views || 'N/A'}
              link={card.site_url}
              platform={card.platform}
              category={card.category}
              company={card.company}
              slug={card.id || 'N/A'}
              externalButtonLabel='Visit Site'
            />
          ))
        )}

        <div
          ref={bottomSentinel}
          style={{ height: '1px', marginTop: '-1px' }}
        />

        <ScrollToTopButton onClick={scrollToTop}>
          <UpArrowIcon name='arrow up' />
        </ScrollToTopButton>
      </GridContainer>

      {loading && hasMore && cardsData.length ? (
        <GridContainer>
          {Array.from(new Array(6)).map((_, index) => (
            <SkeletonLoader key={index} />
          ))}
        </GridContainer>
      ) : null}
    </>
  );
}
