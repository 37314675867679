import React, { useRef, useState } from 'react';
import GridContainer from '../Containers/GridContainer';
import AutocompleteDropdown from '../Inputs/AutocompleteDropdown';
import GameSearchInput from './GameSearchInput';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

const FilterButton = styled(Button)(({ theme }) => ({
  minWidth: '1px !important',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(4),
  color: 'white',
  '&:hover': {
    backgroundColor: 'white',
    color: 'var(--secondary-color)',
  },
}));

const FilterToggleButton = styled('div')(({ theme, active }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  cursor: 'pointer',
  marginBottom: '32px',
  color: active ? 'var(--primary-color)' : 'var(--secondary-color)',
  '&:hover': {
    color: 'var(--primary-color)',
  },
}));

const FilterIcon = styled(FilterListIcon)({
  marginRight: '8px',
});

const FilterText = styled('span')({
  fontSize: '0.875rem', // Reduce the font size
});

const FiltersContainer = styled('div')(({ showFilters }) => ({
  maxHeight: showFilters ? '1000px' : '0',
  opacity: showFilters ? 1 : 0,
  // overflow: 'hidden',
  transition: 'max-height 0.3s ease, opacity 0.3s ease',
}));

const FiltersActions = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '1.5rem',
  marginBottom: theme.spacing(4),
}));

const ResetFiltersButton = styled(Button)(() => ({}));

const ApplyFiltersButton = styled(Button)(() => ({}));

const Filters = ({ onApplyFilters }) => {
  const [showFilters, setShowFilters] = useState(false);
  const filtersRef = useRef(null);
  const filterButtonRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [platform, setPlatform] = useState(null);

  const fetchPlatforms = async (searchTerm = '') => {
    const endpoint = searchTerm
      ? `https://backend-apis.utilitips.com/wp-json/ut/v1/search-games?search=${searchTerm}`
      : 'https://backend-apis.utilitips.com/wp-json/ut/v1/fetch-games';

    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.map((platform) => ({
        label: platform.title,
        id: platform.id,
        slug: platform.slug,
      }));
    } catch (error) {
      console.error('Error fetching platforms:', error);
      return [];
    }
  };

  const handleApplyFilters = () => {
    onApplyFilters({
      searchTerm,
      platform: platform ? platform.slug : '',
    });

    setShowFilters(false);
  };

  const handleResetFilters = () => {
    setSearchTerm('');
    setPlatform(null);
    onApplyFilters({
      searchTerm: '',
      platform: '',
    });

    setShowFilters(false);
  };

  return (
    <>
      {/* <FilterButton
        onClick={() => setShowFilters((prev) => !prev)}
        ref={filterButtonRef}
      >
        <FilterIcon className='fas fa-filter' /> Filters
      </FilterButton> */}
      <FilterToggleButton
        onClick={() => setShowFilters(!showFilters)}
        active={showFilters}
      >
        <FilterIcon />
        <FilterText>Filters</FilterText>
      </FilterToggleButton>

      {/* <FiltersContainer
        ref={filtersRef}
        showFilters={showFilters}
        id='filtersWrapper'
      ></FiltersContainer> */}
      <FiltersContainer
        ref={filtersRef}
        showFilters={showFilters}
        id='filtersWrapper'
      >
        <GridContainer>
          <GameSearchInput value={searchTerm} onChange={setSearchTerm} />
          <FiltersActions>
            <ResetFiltersButton
              variant='outlined'
              size='large'
              onClick={handleResetFilters}
            >
              Reset
            </ResetFiltersButton>
            <ApplyFiltersButton
              variant='contained'
              size='large'
              onClick={handleApplyFilters}
            >
              Apply
            </ApplyFiltersButton>
          </FiltersActions>
        </GridContainer>
      </FiltersContainer>
    </>
  );
};

export default Filters;
