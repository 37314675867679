import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

export default function AutocompleteDropdown({
  label,
  fetchOptions,
  options: staticOptions = [],
  value,
  onChange,
}) {
  const [options, setOptions] = useState(staticOptions);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!fetchOptions) {
      setOptions(staticOptions);
    }
  }, [staticOptions, fetchOptions]);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleSearch = useCallback(
    debounce(async (newInputValue) => {
      if (newInputValue) {
        setLoading(true);
        const fetchedOptions = await fetchOptions(newInputValue);
        setOptions(fetchedOptions);
        setLoading(false);
      }
    }, 300),
    [fetchOptions]
  );

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue === '' && fetchOptions) {
      setOptions([]);
    } else if (fetchOptions) {
      handleSearch(newInputValue);
    }
  };

  const handleFocus = async () => {
    if (fetchOptions && options.length === 0) {
      setLoading(true);
      const fetchedOptions = await fetchOptions();
      setOptions(fetchedOptions);
      setLoading(false);
    }
  };

  return (
    <Autocomplete
      disablePortal
      options={options}
      getOptionLabel={(option) => option.label || ''}
      onInputChange={handleInputChange}
      onFocus={handleFocus}
      onChange={onChange}
      value={value || null}
      inputValue={inputValue}
      loading={loading}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField
          sx={{ bgcolor: 'white' }} // Set background color to white
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && fetchOptions ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
