import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

const PageHeading = styled(Typography)(() => ({
  margin: 'calc(65px - 1rem) 0 !important',
  fontSize: '2rem',
  textAlign: 'center',
  color: 'var(--secondary-color)',
  position: 'relative',
}));

export default function PageHeadingContainer(props) {
  return <PageHeading component='h1'>{props.pageHeading}</PageHeading>;
}
