import React from 'react';
import CardMetaData from '../Shared/CardMetaData';

const ToolMetaData = ({
  className,
  type,
  categories = [],
  company = [],
  platform = [],
}) => {
  const combinedMetaData = [
    ...(type ? [{ name: type, type: 'type' }] : []),
    ...categories.map((item) => ({ ...item, type: 'category' })),
    ...company.map((item) => ({ ...item, type: 'company' })),
    ...platform.map((item) => ({ ...item, type: 'platform' })),
  ];

  return <CardMetaData className={className} metaData={combinedMetaData} />;
};

export default ToolMetaData;
