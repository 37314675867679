import React, { useState, useEffect } from 'react';

import GamesListing from '../Games/GamesListing';
import GamesFilter from '../Games/GamesFilters';
import PageHeadingContainer from '../Containers/PageHeadingContainer';

const Games = () => {
  const [filters, setFilters] = useState({
    searchTerm: '',
    platform: '',
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const initialFilters = {
      searchTerm: urlParams.get('search') || '',
      platform: urlParams.get('platform') || '',
    };
    setFilters(initialFilters);
  }, []);

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div>
      <PageHeadingContainer pageHeading='Over 11k Games' />

      <GamesFilter onApplyFilters={handleApplyFilters} />

      <GamesListing filters={filters} />
    </div>
  );
};

export default Games;
