import React, { useRef, useState } from 'react';
import GridContainer from '../Containers/GridContainer';
import AutocompleteDropdown from '../Inputs/AutocompleteDropdown';
import SearchInput from './ToolSearchInput';
import { styled } from '@mui/material/styles';
import { Button, IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

const FilterButton = styled(Button)(({ theme }) => ({
  minWidth: '1px !important',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(4),
  color: 'white',
  '&:hover': {
    backgroundColor: 'white',
    color: 'var(--secondary-color)',
  },
}));

const FilterToggleButton = styled('div')(({ theme, active }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  cursor: 'pointer',
  marginBottom: '32px',
  color: active ? 'var(--primary-color)' : 'var(--secondary-color)',
  '&:hover': {
    color: 'var(--primary-color)',
  },
}));

const FilterIcon = styled(FilterListIcon)({
  marginRight: '8px',
});

const FilterText = styled('span')({
  fontSize: '0.875rem',
});

const ResetFiltersButton = styled(Button)(() => ({}));

const ApplyFiltersButton = styled(Button)(() => ({}));

const FiltersContainer = styled('div')(({ showFilters }) => ({
  maxHeight: showFilters ? '1000px' : '0',
  opacity: showFilters ? 1 : 0,
  // overflow: 'hidden',
  transition: 'max-height 0.3s ease, opacity 0.3s ease',
}));

const FiltersActions = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '1.5rem',
  marginBottom: theme.spacing(4),
}));

const Filters = ({ onApplyFilters }) => {
  const [showFilters, setShowFilters] = useState(false);
  const filtersRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [type, setType] = useState(null);
  const [category, setCategory] = useState(null);
  const [company, setCompany] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [sort, setSort] = useState(null);

  const typeOptions = [
    { label: 'Free', value: 'free' },
    { label: 'Freemium', value: 'freemium' },
    { label: 'Premium', value: 'premium' },
  ];

  const sortOptions = [
    { label: 'Alphabetically (A-Z)', value: 'title_asc' },
    { label: 'Alphabetically (Z-A)', value: 'title_desc' },
    { label: 'Oldest to Newest', value: 'date_asc' },
    { label: 'Newest to Oldest', value: 'date_desc' },
    { label: 'Most Viewed', value: 'views_desc' },
    { label: 'Least Viewed', value: 'views_asc' },
  ];

  const fetchCategories = async (searchTerm = '') => {
    const endpoint = searchTerm
      ? `https://backend-apis.utilitips.com/wp-json/ut/v1/search-tool-categories?search=${searchTerm}`
      : 'https://backend-apis.utilitips.com/wp-json/ut/v1/fetch-tool-categories';

    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.map((category) => ({
        label: category.title,
        id: category.id,
        slug: category.slug,
      }));
    } catch (error) {
      console.error('Error fetching categories:', error);
      return [];
    }
  };

  const fetchCompanies = async (searchTerm = '') => {
    const endpoint = searchTerm
      ? `https://backend-apis.utilitips.com/wp-json/ut/v1/search-tool-companies?search=${searchTerm}`
      : 'https://backend-apis.utilitips.com/wp-json/ut/v1/fetch-tool-companies';

    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.map((company) => ({
        label: company.title,
        id: company.id,
        slug: company.slug,
      }));
    } catch (error) {
      console.error('Error fetching companies:', error);
      return [];
    }
  };

  const fetchPlatforms = async (searchTerm = '') => {
    const endpoint = searchTerm
      ? `https://backend-apis.utilitips.com/wp-json/ut/v1/search-tool-platforms?search=${searchTerm}`
      : 'https://backend-apis.utilitips.com/wp-json/ut/v1/fetch-tool-platforms';

    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.map((platform) => ({
        label: platform.title,
        id: platform.id,
        slug: platform.slug,
      }));
    } catch (error) {
      console.error('Error fetching platforms:', error);
      return [];
    }
  };

  const handleApplyFilters = () => {
    onApplyFilters({
      searchTerm,
      type: type ? type.value : '',
      category: category ? category.id : '',
      company: company ? company.id : '',
      platform: platform ? platform.id : '',
      sort: sort ? sort.value : 'title_asc',
    });

    setShowFilters(false);
  };

  const handleResetFilters = () => {
    setSearchTerm('');
    setType(null);
    setCategory(null);
    setCompany(null);
    setPlatform(null);
    setSort(null);
    onApplyFilters({
      searchTerm: '',
      type: '',
      category: '',
      company: '',
      platform: '',
      sort: 'title_asc',
    });

    setShowFilters(false);
  };

  return (
    <>
      <FilterToggleButton
        onClick={() => setShowFilters(!showFilters)}
        active={showFilters}
      >
        <FilterIcon />
        <FilterText>Filters</FilterText>
      </FilterToggleButton>
      <FiltersContainer
        showFilters={showFilters}
        ref={filtersRef}
        id='filtersWrapper'
      >
        <GridContainer>
          <SearchInput value={searchTerm} onChange={setSearchTerm} />
          <AutocompleteDropdown
            options={typeOptions}
            label='Type'
            value={type}
            onChange={(event, newValue) => setType(newValue)}
          />
          <AutocompleteDropdown
            label='Category'
            value={category}
            onChange={(event, newValue) => setCategory(newValue)}
            fetchOptions={fetchCategories}
          />
          <AutocompleteDropdown
            label='Company'
            value={company}
            onChange={(event, newValue) => setCompany(newValue)}
            fetchOptions={fetchCompanies}
          />
          <AutocompleteDropdown
            label='Platform'
            value={platform}
            onChange={(event, newValue) => setPlatform(newValue)}
            fetchOptions={fetchPlatforms}
          />
          <AutocompleteDropdown
            options={sortOptions}
            label='Sort by'
            value={sort}
            onChange={(event, newValue) => setSort(newValue)}
          />
          <FiltersActions>
            <ResetFiltersButton
              variant='outlined'
              size='large'
              onClick={handleResetFilters}
            >
              Reset
            </ResetFiltersButton>
            <ApplyFiltersButton
              variant='contained'
              size='large'
              onClick={handleApplyFilters}
            >
              Apply
            </ApplyFiltersButton>
          </FiltersActions>
        </GridContainer>
      </FiltersContainer>
    </>
  );
};

export default Filters;
