import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import GridContainer from '../Containers/GridContainer';
import PostCard from './PostCard';
import SkeletonLoader from '../Cards/SkeletonCard';
import { styled } from '@mui/material/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Typography from '@mui/material/Typography';

const ScrollToTopButton = styled('button')(() => ({
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  backgroundColor: 'var(--brand-main-color)',
  color: 'white',
  border: 'none',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
  transition: '0.3s ease',
  '&:hover': {
    backgroundColor: 'var(--primary-color)',
  },
}));

const UpArrowIcon = styled(ArrowUpwardIcon)(() => ({
  marginRight: '0 !important',
}));

export default function PostsListing({ filters }) {
  const [postsData, setPostsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortOrder, setSortOrder] = useState('title_asc');
  const observer = useRef();
  const bottomSentinel = useRef();
  const location = useLocation(); // Hook to track URL changes

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'UtiliTips - Blog Posts';
  }, []);

  const fetchFeaturedImage = async (featuredMediaId) => {
    try {
      const mediaResponse = await fetch(
        `https://backend-apis.utilitips.com/wp-json/wp/v2/media/${featuredMediaId}`
      );
      if (!mediaResponse.ok) {
        throw new Error(
          `HTTP error fetching media! status: ${mediaResponse.status}`
        );
      }
      const mediaData = await mediaResponse.json();
      return mediaData.source_url;
    } catch (error) {
      console.error('Error fetching featured image:', error);
      return null;
    }
  };

  const fetchPosts = async (page, filters, sortOrder) => {
    setLoading(true);
    try {
      const query = new URLSearchParams({
        page,
        search: filters.searchTerm,
        category: filters.category,
        sort: sortOrder,
      }).toString();

      const response = await fetch(
        `https://backend-apis.utilitips.com/wp-json/wp/v2/posts?type=post&per_page=6&${query}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      // If no data is returned and we are on the first page, set hasMore to false
      if (data.length === 0 && page === 1) {
        setHasMore(false);
        setPostsData([]); // No posts to display
        return;
      }

      // If no data is returned and we're not on the first page, it means no more posts
      if (data.length === 0) {
        setHasMore(false);
        return;
      }

      // Fetch the featured images for each post
      const postsWithImages = await Promise.all(
        data.map(async (post) => {
          const featuredImageUrl = post.featured_media
            ? await fetchFeaturedImage(post.featured_media)
            : null;
          return { ...post, featured_image_url: featuredImageUrl };
        })
      );

      setPostsData((prevPosts) =>
        page === 1 ? postsWithImages : [...prevPosts, ...postsWithImages]
      );

      // If fewer posts are returned than the limit, we've reached the end
      if (data.length < 6) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
    setPostsData([]);
    fetchPosts(1, filters, sortOrder);
  }, [filters, sortOrder]);

  useEffect(() => {
    if (page > 1 && hasMore) {
      fetchPosts(page, filters, sortOrder);
    }
  }, [page, filters, sortOrder, hasMore]);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && !loading && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    [loading, hasMore]
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '100px',
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver(handleObserver, options);
    if (bottomSentinel.current) {
      observer.current.observe(bottomSentinel.current);
    }

    return () => {
      if (observer.current && bottomSentinel.current) {
        observer.current.unobserve(bottomSentinel.current);
      }
    };
  }, [handleObserver]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Sync URL with filters and page
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('page', page);
    if (filters.searchTerm) {
      urlParams.set('search', filters.searchTerm);
    } else {
      urlParams.delete('search');
    }
    if (filters.category) {
      urlParams.set('category', filters.category);
    } else {
      urlParams.delete('category');
    }
    window.history.pushState({}, '', `?${urlParams.toString()}`);
  }, [page, filters, sortOrder, location.search]);

  return (
    <>
      <GridContainer>
        {loading && !postsData.length ? (
          Array.from(new Array(12)).map((_, index) => (
            <SkeletonLoader key={index} />
          ))
        ) : postsData.length === 0 && !loading ? (
          <Typography variant='h6'>No posts found!</Typography>
        ) : (
          postsData.map((post, index) => (
            <PostCard
              key={index}
              image={post.featured_image_url}
              title={post.title.rendered}
              excerpt={post.excerpt.rendered}
              link={post.link}
              slug={post.slug}
              externalButtonLabel='Visit Site'
            />
          ))
        )}

        <div
          ref={bottomSentinel}
          style={{ height: '1px', marginTop: '-1px' }}
        />

        <ScrollToTopButton onClick={scrollToTop}>
          <UpArrowIcon name='arrow up' />
        </ScrollToTopButton>
      </GridContainer>

      {loading && hasMore && postsData.length ? (
        <GridContainer>
          {Array.from(new Array(6)).map((_, index) => (
            <SkeletonLoader key={index} />
          ))}
        </GridContainer>
      ) : null}
    </>
  );
}
