import React, { useState, useEffect } from 'react';

import PageHeadingContainer from '../Containers/PageHeadingContainer';
import PostsListing from '../Posts/PostsListing';
import PostsFilters from '../Posts/PostsFilters';

const Games = () => {
  const [filters, setFilters] = useState({
    searchTerm: '',
    platform: '',
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const initialFilters = {
      searchTerm: urlParams.get('search') || '',
    };
    setFilters(initialFilters);
  }, []);

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div>
      <PageHeadingContainer pageHeading='Blog' />

      <PostsFilters onApplyFilters={handleApplyFilters} />

      <PostsListing filters={filters} />
    </div>
  );
};

export default Games;
