import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import CardActions from '@mui/material/CardActions';

const CardImageContainer = styled('div')(() => ({
  display: 'block',
  height: '225px',
  width: '100%',
  overflow: 'hidden',
}));

const CardSkeleton = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const CardTitleContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const SkeletonLoader = () => (
  <CardSkeleton>
    <CardImageContainer>
      <Skeleton variant='rectangular' width='100%' height={225} />
    </CardImageContainer>

    <CardContent>
      <CardTitleContainer>
        <Skeleton variant='text' width='60%' height={30} />
        <Skeleton variant='text' width='20%' height={30} />
      </CardTitleContainer>
      <Skeleton variant='text' width='80%' height={20} />
      <Skeleton variant='text' width='80%' height={20} />
    </CardContent>

    <CardActions>
      <Skeleton variant='rectangular' width='30%' height={40} />
    </CardActions>
  </CardSkeleton>
);

export default SkeletonLoader;
