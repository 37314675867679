import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

const SearchAutocomplete = styled(Autocomplete)(({ theme }) => ({
  backgroundColor: 'white',
}));

export default function GamesSearchInput({ value, onChange }) {
  const [options, setOptions] = useState([]);
  const debounceTimeoutRef = useRef(null);

  useEffect(() => {
    if (value.trim() === '') {
      setOptions([]);
      return;
    }

    const fetchOptions = async () => {
      try {
        const response = await fetch(
          `https://backend-apis.utilitips.com/wp-json/ut/v1/search-games?search=${encodeURIComponent(
            value
          )}&page=1`
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (data) {
          setOptions(data.map((item) => item.title));
        } else {
          setOptions([]);
        }
      } catch (error) {
        console.error('Error fetching search options:', error);
        setOptions([]);
      }
    };

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      fetchOptions();
    }, 300);

    return () => clearTimeout(debounceTimeoutRef.current);
  }, [value]);

  return (
    <Stack spacing={2}>
      <SearchAutocomplete
        freeSolo
        id='free-solo-2-demo'
        disableClearable
        options={options}
        inputValue={value}
        onInputChange={(event, newInputValue) => {
          onChange(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Search games'
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
    </Stack>
  );
}
