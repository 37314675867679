import React from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import LaunchIcon from '@mui/icons-material/Launch';

const StyledCard = styled(Card)(() => ({
  position: 'relative',
  display: 'grid',
  placeContent: 'start',
}));

const stripHtmlTags = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

const CardImageContainer = styled('a')(() => ({
  display: 'block',
  height: '225px',
  width: '100%',
  overflow: 'hidden',
}));

const CardImage = styled(CardMedia)(() => ({
  transition: '0.3s',
  height: '100%',
  objectFit: 'cover',

  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const CardTitleContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const StyledTitle = styled(Typography)(() => ({
  color: 'var(--primary-color) !important',
}));

const ButtonsWrapper = styled(CardActions)(() => ({
  justifyContent: 'flex-end',
  padding: '16px',
}));

const CardButton = styled(Button)(() => ({
  color: 'white !important',
}));

const VisitSite = styled('span')(() => ({
  color: 'var(--primary-color) !important',
}));

const NewTabIcon = styled(LaunchIcon)(() => ({
  marginLeft: '0.25rem !important',
  fontSize: '12px !important',
  color: 'var(--primary-color) !important',
}));

export default function PostCard({
  image,
  title,
  excerpt,
  link,
  slug,
  categories = [],
  externalButtonLabel,
}) {
  return (
    <StyledCard>
      {image ? (
        <CardImageContainer href={`/post/${slug}`}>
          <CardImage
            component='img'
            alt={`Image of ${title}`}
            height='225'
            image={image}
          />
        </CardImageContainer>
      ) : null}

      <CardContent>
        <CardTitleContainer>
          <StyledTitle
            gutterBottom
            variant='h5'
            component={Link} // Make the title a clickable link
            to={`/post/${slug}`} // Link to the post detail page
            style={{ textDecoration: 'none', color: 'inherit' }} // Optional: remove underline and use inherited color
          >
            {title}
          </StyledTitle>
        </CardTitleContainer>
        <Typography variant='body2' color='text.secondary'>
          {stripHtmlTags(excerpt)}
        </Typography>
      </CardContent>

      <ButtonsWrapper>
        <CardButton
          component={Link}
          size='small'
          to={`/post/${slug}`} // Link to the post detail page
          variant='contained'
        >
          Read More
        </CardButton>
      </ButtonsWrapper>
    </StyledCard>
  );
}
