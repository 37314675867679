import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Home from './components/Pages/Home';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import Games from './components/Pages/Games';
import ToolDetails from './components/Tools/ToolDetails';
import GameDetails from './components/Games/GameDetails';
import Blog from './components/Pages/Blog'; // Correct import path for Blog component
import PostDetails from './components/Posts/PostDetails'; // Correct import path for PostDetails

function App() {
  const [filters, setFilters] = React.useState({});

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <Router>
      <div className='App'>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/tool/:id' element={<ToolDetails />} />
          <Route path='/games' element={<Games />} />
          <Route path='/game/:id' element={<GameDetails />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/post/:slug' element={<PostDetails />} />{' '}
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
