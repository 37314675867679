// components/Filters/PostsFilters.js
import React, { useRef, useState } from 'react';
import GridContainer from '../Containers/GridContainer';
import AutocompleteDropdown from '../Inputs/AutocompleteDropdown';
import SearchInput from './PostSearchInput';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

const FilterButton = styled(Button)(({ theme }) => ({
  minWidth: '1px !important',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(4),
  color: 'white',
  '&:hover': {
    backgroundColor: 'white',
    color: 'var(--secondary-color)',
  },
}));

const FilterToggleButton = styled('div')(({ theme, active }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  cursor: 'pointer',
  marginBottom: '32px',
  color: active ? 'var(--primary-color)' : 'var(--secondary-color)',
  '&:hover': {
    color: 'var(--primary-color)',
  },
}));

const FilterIcon = styled(FilterListIcon)({
  marginRight: '8px',
});

const FilterText = styled('span')({
  fontSize: '0.875rem', // Reduce the font size
});

const FiltersContainer = styled('div')(({ showFilters }) => ({
  maxHeight: showFilters ? '1000px' : '0',
  opacity: showFilters ? 1 : 0,
  // overflow: 'hidden',
  transition: 'max-height 0.3s ease, opacity 0.3s ease',
}));

const FiltersActions = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '1.5rem',
  marginBottom: theme.spacing(4),
}));

const ResetFiltersButton = styled(Button)(() => ({}));

const ApplyFiltersButton = styled(Button)(() => ({}));

const PostsFilters = ({ onApplyFilters }) => {
  const [showFilters, setShowFilters] = useState(false);
  const filtersRef = useRef(null);
  const filterButtonRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState(null);
  const [author, setAuthor] = useState(null);

  const fetchCategories = async (searchTerm = '') => {
    const endpoint = searchTerm
      ? `https://backend-apis.utilitips.com/wp-json/wp/v2/categories?search=${searchTerm}`
      : 'https://backend-apis.utilitips.com/wp-json/wp/v2/categories';

    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.map((category) => ({
        label: category.name,
        id: category.id,
        slug: category.slug,
      }));
    } catch (error) {
      console.error('Error fetching categories:', error);
      return [];
    }
  };

  const handleApplyFilters = () => {
    onApplyFilters({
      searchTerm,
      category: category ? category.slug : '',
      author: author ? author.slug : '',
    });

    setShowFilters(false);
  };

  const handleResetFilters = () => {
    setSearchTerm('');
    setCategory(null);
    setAuthor(null);
    onApplyFilters({
      searchTerm: '',
      category: '',
      author: '',
    });

    setShowFilters(false);
  };

  return (
    <>
      <FilterToggleButton
        onClick={() => setShowFilters(!showFilters)}
        active={showFilters}
      >
        <FilterIcon />
        <FilterText>Filters</FilterText>
      </FilterToggleButton>

      <FiltersContainer
        ref={filtersRef}
        showFilters={showFilters}
        id='filtersWrapper'
      >
        <GridContainer>
          <SearchInput value={searchTerm} onChange={setSearchTerm} />
          {/* <AutocompleteDropdown
            label='Category'
            value={category}
            onChange={(event, newValue) => setCategory(newValue)}
            fetchOptions={fetchCategories}
          /> */}
          <FiltersActions>
            <ResetFiltersButton
              variant='outlined'
              size='large'
              onClick={handleResetFilters}
            >
              Reset
            </ResetFiltersButton>
            <ApplyFiltersButton
              variant='contained'
              size='large'
              onClick={handleApplyFilters}
            >
              Apply
            </ApplyFiltersButton>
          </FiltersActions>
        </GridContainer>
      </FiltersContainer>
    </>
  );
};

export default PostsFilters;
